//Hook
import RuleMessage from "@logic/forms/validators/rules/RuleMessage";

import {useAppSelector} from '@store/hooks/hooks';


type Text = {
    [key: string]: RuleMessage;
};

export type Texts = {
    com: Text,
    uz: Text,
    ru: Text,
};

export default function useLanguageTexts (texts: Texts) {
    const {site, language} = useAppSelector(state => state.generalStatesSlice);
    const obj = texts[site] ?? texts.com;

    return (key: string) => (obj?.[key]?.[language]) ?? "";


}
