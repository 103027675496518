import RuleMessage from "@logic/forms/validators/rules/RuleMessage";

const taskCreator: {
    [key: string]: RuleMessage;
} = {
    uploadFile: {
        ru: `Загрузите файл`,
        en: `Upload the file`,
        lt: `Atsisiųskite failą`,
        uz: ``,
    },
    generalInfoTask: {
        ru: `Общая информация о проекте`,
        en: `General information about the project`,
        lt: `Bendroji informacija apie projektą`,
        uz: ``,
    },
    paymentInfo: {
        ru: `Информация об оплате`,
        en: `Payment information`,
        lt: 'Mokėjimo informacija',
        uz: ``,
    },
    fullPaymentInfo: {
        ru: `Полная информация об оплате`,
        en: `Full payment information`,
        lt: `Pilna mokėjimo informacija`,
        uz: ``,
    },
    attachFiles: {
        ru: `Прикрепить файлы`,
        en: `Attach files`,
        lt: `Pridėti failus`,
        uz: ``,
    },
    releaseToDownload: {
        ru: `Отпустите файлы, чтобы загрузить`,
        en: `Release to download the file`,
        lt: `Atleiskite failus, kad atsisiųstumėte`,
        uz: ``,
    },
    downLoadFile: {
        ru: `Загрузить .xml файл`,
        en: `Download .xml file`,
        lt: `Įkelti .xml failas`,
        uz: ``,
    },
    createTask: {
        ru: `Создать задачу`,
        en: `Create task`,
        lt: `Sukurti užduotį`,
        uz: ``,
    },
    title: {
        ru: `Создать задачу`,
        en: `Create a task`,
        lt: `Sukurti užduotį`,
        uz: ``,
    },
    needToRemoveErrors:{
        ru: 'Устраните ошибки, чтобы импортировать задачи',
        en: 'Remove errors to import tasks',
        lt: 'Pašalinkite klaidas, kad importuotumėte užduotis',
        uz: ``,
    },
    generalInformation: {
        ru: `Детали задачи`,
        en: `Task details`,
        lt: `Užduoties informacija`,
        uz: ``,
    },
    attributeInformation: {
        ru: `Детали атрибута`,
        en: `Attribute details`,
        lt: ``,
        uz: ``,
    },
    nameOfTask: {
        ru: `Название задачи`,
        en: `Task name`,
        lt: `Užduoties pavadinimas`,
        uz: ``,
    },
    typeOfCategory: {
        ru: `Категория работ`,
        en: `Category of work`,
        lt: `Kategorija darbų`,
        uz: ``,
    },
    selectCategory: {
        ru: `Выберите категорию`,
        en: `Select a category`,
        lt: `Pasirinkite kategoriją`,
        uz: ``,
    },
    typeOfWork: {
        ru: `Тип работ`,
        en: `Type of work`,
        lt: `Darbų tipas`,
        uz: ``,
    },
    taskNamePlaceholder: {
        ru: 'Введите название, отражающее суть задачи',
        en: 'Enter a name that reflects the essence of the task',
        lt: 'Įveskite pavadinimą, atspindintį užduoties esmę',
        uz: ``,
    },
    name: {
        ru: `Название`,
        en: `Name`,
        lt: `Pavadinimas`,
        uz: ``,
    },
    selectTypeWork: {
        ru: `Нажмите чтобы выбрать тип работ`,
        en: `Click to select the job type`,
        lt: `Spustelėkite norėdami pasirinkti darbo tipą`,
        uz: ``,
    },
    projectDescription: {
        ru: `Описание проекта`,
        en: `Project Description`,
        lt: `Projekto aprašymas`,
        uz: ``,
    },
    addThePerformer: {
        ru: `Добавить исполнителя`,
        en: `Add the Performer`,
        lt: `Pridėti vykdytoją`,
        uz: ``,
    },
    performers: {
        ru: `Исполнители`,
        en: `Performers`,
        lt: `Atlikėjas`,
        uz: ``,
    },
    next: {
        ru: `Далее`,
        en: `Next`,
        lt: `Toliau`,
        uz: ``,
    },
    debitedAccount: {
        ru: `Счет списания`,
        en: `Debited Account`,
        lt: `Debeto (nurašymo) sąskaita`,
        uz: ``,
    },
    account: {
        ru: `Счет`,
        en: `Account`,
        lt: `Sąskaita`,
        uz: ``,
    },
    accrualAmount: {
        ru: `Сумма зачисления`,
        en: `Accrual Amount`,
        lt: `Įskaityta suma`,
        uz: ``,
    },
    amount: {
        ru: `Сумма`,
        en: `Amount`,
        lt: `Suma`,
        uz: ``,
    },
    totalAccrualAmount: {
        ru: 'Сумма начислений',
        en: 'Accrual Amount',
        lt: 'Iskaityta suma',
        uz: ``,
    },
    taskPayment: {
        ru: `Оплата задачи`,
        en: `Task payment`,
        lt: `Apmokėjimas už užduotį`,
        uz: ``,
    },
    createOfTask: {
        ru: `Создать`,
        en: `Create`,
        lt: `Sukurti `,
        uz: ``,
    },
    taskDescription: {
        ru: `Описание задачи`,
        en: `Task description`,
        lt: `Užduoties aprašymas`,
        uz: ``,
    },
    taskDescriptionPlaceholder: {
        ru: `Введите описание задачи`,
        en: `Enter a description of the task`,
        lt: `Įveskite užduoties aprašymą`,
        uz: ``,
    },
    attributeDescriptionPlaceholder: {
        ru: `Введите описание атрибута`,
        en: `Enter a description of the attribute`,
        lt: `Įveskite užduoties aprašymą`,
        uz: ``,
    },
    back: {
        ru: `Назад`,
        en: `Back`,
        lt: `Atgal `,
        uz: ``,
    },
    executionDate: {
        ru: `Дата выполнения`,
        en: `Execution date`,
        lt: `Atlikimo data`,
        uz: ``,
    },
    deadline: {
        ru: `Дата сдачи`,
        en: `Deadline`,
        lt: `Terminas`,
        uz: ``,
    },
    selectExecutionDate: {
        ru: `Выберите дату выполнения`,
        en: `Select the execution date`,
        lt: `Pasirinkite atlikimo datą`,
        uz: ``,
    },
    intellectualProperty: {
        ru: `С передачей интеллектуальной собственности`,
        en: `Transferring the intellectual property`,
        lt: `Perduodant intelektinę nuosavybę`,
        uz: ``,
    },

    save: {
        ru: `Сохранить`,
        en: `Save`,
        lt: `Išsaugoti`,
        uz: ``,
    },
    inputEmail: {
        ru: `Введите E-mail или фио исполнителя`,
        en: `Enter the E-mail or the name of the performer`,
        lt: `Įveskite el. pašto adresą arba vykdytojo vardą`,
        uz: ``,
    },
    maxFreelancers: {
        ru: `максимум {0} исполнителей`,
        en: `maximum of {0} performers`,
        lt: `daugiausia {0} atlikėjo`,
        uz: ``,
    },
    serviceProvider: {
        ru: `Нажмите чтобы добавить исполнителя`,
        en: `Click to add the service provider`,
        lt: `Spustelėkite, kad pridėtumėte vykdytoją`,
        uz: ``,
    },
    total: {
        ru: `Итого`,
        en: `Total`,
        lt: `Iš viso:Iš viso`,
        uz: ``,
    },
    commission: {
        ru: `Комиссия`,
        en: `Commission`,
        lt: `Komisija`,
        uz: ``,
    },
    fix: {
        ru: `Фикс`,
        en: `Fix`,
        lt: `Nustatyti`,
        uz: ``,
    },
    nds: {
        ru: `НДС`,
        en: `VAT`,
        lt: `PVM`,
        uz: ``,
    },
    invited: {
        ru: `Приглашен`,
        en: `Invited`,
        lt: `Pakviestas`,
        uz: ``,
    },
    receiveInvited: {
        ru: `Получит приглашение`,
        en: `Will receive an invitation`,
        lt: `GausKvietimą`,
        uz: ``,
    },
    invite: {
        ru: `Пригласить`,
        en: `invite`,
        lt: `Pakviesti`,
        uz: ``,
    },
    attachedFiles: {
        ru: `Прикрепленные файлы`,
        en: `Attached files`,
        lt: `Pridėti failai`,
        uz: ``,
    },
    notValid: {
        ru: `Неверный формат данных`,
        en: `Invalid data format`,
        lt: `Neteisingas duomenų formatas`,
        uz: ``,
    },
    invalidFileFormat: {
        ru: `Неверный формат файла`,
        en: `Invalid file format`,
        lt: `Neteisingas failo formatas`,
        uz: ``,
    },
    fileConditions: {
        ru: `Поддерживаются файлы типа .xlsx и размер не более 5 Мб.`,
        en: `Files of the type are supported .xlsx and no more than 5 MB in size.`,
        lt: `Palaikomi failų tipo .xlsx ir dydis ne daugiau kaip 5 Mb.`,
        uz: ``,
    },
    totalTasks: {
        ru: `Количество задач`,
        en: `Number of tasks`,
        lt: `Užduočių skaičius`,
        uz: ``,
    },
    tasksWithoutErrors: {
        ru: `Задач без ошибок`,
        en: `Tasks without errors`,
        lt: `Užduotis be klaidų`,
        uz: ``,
    },
    sum: {
        ru: `Сумма задач без ошибок`,
        en: `Sum of tasks without errors`,
        lt: `Suma užduotis be klaidų`,
        uz: ``,
    },
    missingName: {
        ru: `Отсутствует имя`,
        en: `Missing name`,
        lt: `Trūksta vardas`,
        uz: ``,
    },
    currencyError: {
        ru: `Валюта не соответствует основному счету`,
        en: `The currency does not match the main account`,
        lt: `Valiuta neatitinka pagrindinio sąskaitą`,
        uz: ``,
    },
    currencySelect: {
        ru: `Ваюта`,
        en: `Currency`,
        lt: `Currency`,
        uz: ``,
    },
    freelancerError: {
        ru: `Требуется исполнитель`,
        en: `A freelancer is required`,
        lt: `Norite atlikėjas`
        ,
        uz: ``,
    },
    paymentContractorError: {
        ru: `Задайте выплату фрилансеру`,
        en: `Set the payment to the freelancer`,
        lt: `Užduokite mokėti atlikėją`
        ,
        uz: ``,
    },
    workError: {
        ru: `Неверный тип работ`,
        en: `Incorrect job type`,
        lt: `Neteisingas tipo darbo`
        ,
        uz: ``,
    },
    createOfTasks: {
        ru: `Создать задачи`,
        en: `Create Tasks`,
        lt: `Sukurti užduotis`,
        uz: ``,
    },
    emailError: {
        ru: `Неверный формат почты`,
        en: `Invalid mail format`,
        lt: `Formatas neteisingas el. pašto`,
        uz: ''
    },
    tasksAmount: {
        ru: `Сумма задач`,
        en: `Amount of tasks`,
        lt: `Suma užduotis`,
        uz: ``,
    },
    commissionAmount: {
        ru: `Сумма комиссии`,
        en: `Commission amount`,
        lt: `Komisijos suma`,
        uz: ``,
    },
    fullAmount: {
        ru: `Полная сумма`,
        en: `Full amount`,
        lt: `Visa suma`,
        uz: ``,
    },
    importTask: {
        ru: `Импорт списка`,
        en: `Importing a list`,
        lt: `Importo sąrašą`,
        uz: ``,
    },
    importTaskTitle: {
        ru: `Импорт задач`,
        en: `Import tasks`,
        lt: `Importo užduotis`,
        uz: ``,
    },
    viewingTasks: {
        ru: `Просмотр задач`,
        en: `Task view`,
        lt: `Peržiūrėti užduotis`,
        uz: ``,
    },
    toImport: {
        ru: `Импортировать`,
        en: `Import`,
        lt: `Importuoti`,
        uz: ''
    },
    downloadLayout: {
        ru: `Скачать шаблон`,
        en: `Download the template`,
        lt: `Atsisiųsti šabloną`
        ,
        uz: ``,
    },

    minAmount: {
        ru: `Не верная минимальная сумма у фрилансера`,
        en: `The minimum amount of the freelancer is incorrect`,
        lt: `Neteisingas mažiausia suma, jūs freelancer`,
        uz: ``,
    },
    convert: {
        ru: `Конвертация`,
        en: `Convert`,
        lt: `Convert`,
        uz: ``,
    },
    nothingFound: {
        ru: `Ничего не найдено`,
        en: `Nothing found`,
        lt: `Nieko nerasta`,
        uz: `Hech narsa topilmadi`,
    },
    warnConvert: {
        ru: 'Обратите внимание: курс указан на момент назначения задачи, в момент выплаты сумма валюты может быть пересчитана и выплачена фрилансеру по актуальному курсу',
        en: 'Please note: the exchange rate is specified at the time of assignment of the task, at the time of payment, the amount of currency can be recalculated and paid to the freelancer at the current rate',
        lt: 'Atkreipkite dėmesį: kursas yra nurodytas užduoties paskirties metu, mokėjimo metu valiutos suma gali būti perskaičiuota ir mokama laisvai samdomam vertėjui pagal dabartinį kursą',
        uz: 'Iltimos, diqqat qiling: kurs topshiriq berilgan paytda ko\'rsatiladi, to\'lov vaqtida valyuta summasi qayta hisoblab chiqilishi va frilanserga joriy kurs bo\'yicha to\'lanishi mumkin'
    },
    amountPlaceholder: {
        ru: 'Введите сумму',
        en: 'Enter the amount',
        lt: 'Įveskite sumą',
        uz: 'Summani kiriting'
    },
    with: {
        ru: 'С',
        en: 'With',
        lt: 'Su',
        uz: 'Bilan'
    },
    isIntellectualProperty: {
        ru: 'передачей прав интеллектуальной собственности',
        en: 'the transfer of intellectual property rights',
        lt: 'intelektinės nuosavybės teisių perdavimu',
        uz: 'Maktabga o\'tkazilgan huquqiy mulkni o\'tkazish'
    },
    isNda: {
        ru: 'Соглашением о конфиденциальности (NDA)',
        en: 'a Non-Disclosure Agreement (NDA)',
        lt: 'konfidencialumo sutartimi (NDA)',
        uz: 'Maxfiylik shartnomasi (NDA)'
    },
    maxSizeText: {
        ru: 'Максимальный размер файла 5 Мб',
        en: 'Maximum file size 5 MB',
        lt: 'Maksimalus failo dydis 5 MB',
        uz: 'Maksimal fayl hajmi 5 MB'
    },
    paymentRecipients: {
        ru: 'Получатели платежа',
        en: 'Payment recipients',
        lt: '',
        uz: ''
    },
    paymentRecipient: {
        ru: 'Получатель платежа',
        en: 'Payment recipient',
        lt: '',
        uz: ''
    },
    recipient: {
        ru: 'Получатель',
        en: 'Recipient',
        lt: '',
        uz: ''
    },
    recipients: {
        ru: 'Получатели',
        en: 'Recipients',
        lt: '',
        uz: ''
    },
    currencyWarning: {
        ru: 'Оплата услуг исполнителей доступна только в валюте договора',
        en: 'Payment for the services of performers is available only in the currency specified in the debited account',
        lt: '',
        uz: ''
    },
    minSumWarning: {
        ru: 'Сумма должна быть больше',
        en: 'The amount must be more',
        lt: '',
        uz: ''
    },
    attributeNotFilledWarning: {
        ru: 'Заполните поля со звездочкой* и как минимум 1 поле со знаком ^, чтобы продолжить',
        en: 'Please complete fields marked with an asterisk* and at least 1 field marked with a ^ to continue.',
        lt: 'Norėdami tęsti, užpildykite laukus, pažymėtus žvaigždute*, ir bent vieną lauką, pažymėtą ^.',
        uz: 'Davom etish uchun yulduzcha* bilan belgilangan maydonlarni va ^ bilan belgilangan kamida bitta maydonni toʻldiring.'
    },
    attributeTypeNumberValidationError: {
        ru: 'Введите числовое значение в правильном формате.',
        en: 'Enter a numerical value in the correct format.',
        lt: '',
        uz: ''
    },
    attributeTypeEmailValidationError: {
        ru: 'Введен неверный формат адреса электронной почты.',
        en: 'The email address format you entered is incorrect.',
        lt: '',
        uz: ''
    },
};


export default taskCreator;
