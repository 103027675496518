import com from '@logic/language/com/account/common/taskCreator';

const taskCreatorTexts = {
    com,
    ru:com,
    uz:com
};

export default taskCreatorTexts;


